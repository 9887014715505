import React from 'react';
import Sidebar from 'src/components/Sidebar/Sidebar';
import styled from 'styled-components';
import TwoRowListGrid from './Article/ListGrid/TwoRowArticleListGrid';
import { useRouter } from 'next/router';
import RouterFallback from 'src/components/RouterFallback';
import { useSWRInfiniteWithFallback } from 'src/hooks/use-swr-infinite-with-fallback';
import { ArticlesApi, ArticlesGroup } from 'src/services/articles';
import ContentLayout from 'src/components/Layout/ContentLayout';
import { useAdverts } from 'src/contexts/advertisement';
import TagBar from 'src/components/TagBar/TagBar';
import Image from 'next/legacy/image';
import { imageUrlMaker } from 'src/utils/image-helpers';
import Separator from 'src/components/Separator';
import FormattedFAQ from 'src/components/Formatted/FormattedFAQ';
import BreadCrumbs from 'src/components/BreadCrumbs';
import FormattedRichText from 'src/components/Formatted/FormattedRichText';
import { NextSeo } from 'next-seo';
import FAQPageJsonLd from 'src/components/FAQPageJsonLd';
import { makeArticleGroupSwrKey } from 'src/constants/swr-keys';

const articlesApi = new ArticlesApi();

interface ArticleGroupPageProps {
  slug?: string;
}

const ArticleGroupPageContainer: React.FC<ArticleGroupPageProps> = ({
  slug: _slug,
}) => {
  const router = useRouter();
  const adverts = useAdverts();
  const slug = _slug ?? (router.query.slug as string);
  const fallbackKey = makeArticleGroupSwrKey(slug);

  const {
    data: articleGroups,
    error,
    fetchMore,
    isLoading,
  } = useSWRInfiniteWithFallback<ArticlesGroup>({
    fallbackKey,
    fetcher: cursor => articlesApi.getArticleGroupBySlug(slug, cursor),
    getPaging: prevPageData => prevPageData?.articles?.paging,
  });

  const staticArticleGroupData = articleGroups?.[0];

  const crop = staticArticleGroupData.crops?.landscapeWide;
  const imageUrl =
    staticArticleGroupData.image?.url &&
    imageUrlMaker({
      imageUrl: staticArticleGroupData.image.url,
      cropType: 'landscapeCoverCrop',
      crop,
    });

  return (
    <RouterFallback error={error} loading={!articleGroups && !error}>
      {articleGroups && (
        <>
          <NextSeo
            title={staticArticleGroupData.metaTitle}
            description={staticArticleGroupData.metaDescription}
            twitter={{
              cardType: 'summary_large_image',
            }}
            openGraph={{
              title: staticArticleGroupData.metaTitle,
              description: staticArticleGroupData.metaDescription,
              // images:staticArticleGroupData.image
              //   ? [
              //       {
              //         url: imageUrl ?? '',
              //       },
              //     ]
              //   : [],
            }}
          />
          <FAQPageJsonLd faq={staticArticleGroupData?.faq} />
          <ContentLayout
            main={
              <div>
                <BreadCrumbs
                  currentSlug={slug}
                  paths={[
                    {
                      label: staticArticleGroupData.name,
                      slug: staticArticleGroupData.slug,
                    },
                  ]}
                />
                <TitleCss>{staticArticleGroupData.name}</TitleCss>
                <TagBar tags={staticArticleGroupData.tags} />
                <FormattedRichText text={staticArticleGroupData.intro} />
                {imageUrl && (
                  <ImageWrapperCss>
                    <Image
                      layout="fill"
                      src={imageUrl}
                      alt={staticArticleGroupData?.image?.alt ?? 'description'}
                    />
                  </ImageWrapperCss>
                )}

                <Separator />

                <TwoRowListGrid
                  data={articleGroups?.map(group => group?.articles)}
                  onFetchMore={fetchMore}
                  loading={isLoading}
                />

                <Separator />

                <FormattedRichText text={staticArticleGroupData.description} />

                {staticArticleGroupData.faq &&
                  staticArticleGroupData.faq.length > 0 && (
                    <>
                      <Separator />
                      <FormattedFAQ inputFAQ={staticArticleGroupData.faq} />
                    </>
                  )}
              </div>
            }
            sidebar={<Sidebar adverts={adverts} />}
          />
        </>
      )}
    </RouterFallback>
  );
};

export default ArticleGroupPageContainer;

const TitleCss = styled.h1`
  ${p => p.theme.getFontStyle('primary', 'bold')}
  margin: 0;
  margin-bottom: ${p => p.theme.sizes.spacing5};
  font-size: ${p => p.theme.typography.text3Xl};
`;

const ImageWrapperCss = styled.div`
  position: relative;
  height: 346px;
  width: 100%;
  overflow: hidden;
`;
