import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import { FontIcon } from '@shapegames/web-library-quick-draw-ui/dist';
import { BASE_URL } from 'src/constants/enviroment';
import { BreadcrumbJsonLd } from 'next-seo';

interface BreadCrumbsProps {
  currentSlug: string;
  paths: { slug: string; label: string }[];
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({
  paths: _paths,
  currentSlug,
}) => {
  const paths = [{ slug: '', label: 'Home' }, ..._paths];

  const renderPaths = paths.map((path, idx) => {
    const isLast = idx === paths.length - 1;
    const generatedSlug = _paths.map(x => x.slug).join('/');
    const href = isLast ? generatedSlug : path.slug;

    return (
      <BreadCrumbItemCss key={`${href}`}>
        <Link href={`/${href}`}>
          <BreadCrumbTextCss highlight={currentSlug === path.slug}>
            {path.label}
          </BreadCrumbTextCss>
        </Link>
        {!isLast && <BreadCrumbSeparatorCss name="icon-chevron-right" />}
      </BreadCrumbItemCss>
    );
  });

  return (
    <>
      <BreadcrumbJsonLd
        itemListElements={paths.map((path, idx) => ({
          position: idx + 1,
          name: path.label,
          item: `${BASE_URL}${path.slug}`,
        }))}
      />
      <BreadCrumbWrapperCss>{renderPaths}</BreadCrumbWrapperCss>
    </>
  );
};

export default BreadCrumbs;

const BreadCrumbSeparatorCss = styled(FontIcon)`
  margin: 0 ${p => p.theme.sizes.spacing2};
`;

const BreadCrumbTextCss = styled.span<{ highlight: boolean }>`
  ${p => p.theme.getFontStyle('primary', 'bold')}
  color: ${p =>
    p.highlight ? p.theme.colors.primary500 : p.theme.colors.neutral900};
`;

const BreadCrumbWrapperCss = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${p => p.theme.sizes.spacing4};
`;

const BreadCrumbItemCss = styled.div`
  display: flex;
  align-items: center;
  font-size: ${p => p.theme.typography.textXs};
`;
