import React from 'react';
import styled from 'styled-components';
import { Tag } from 'src/services/articles';
import { Tag as TagComponent } from '@shapegames/web-library-quick-draw-ui/dist/Tag';
import Link from 'src/components/Link';

interface TagBarProps {
  tags: Tag[];
}

const TagBar: React.FC<TagBarProps> = ({ tags }) => {
  return (
    <TagBarWrapperCss>
      {tags.map(tag => (
        <Link key={`tag-${tag.id}`} href={`/tags/${tag.slug}`}>
          <TagCss text={tag.name} bgColor="primary" />
        </Link>
      ))}
    </TagBarWrapperCss>
  );
};

export default TagBar;

const TagCss = styled(TagComponent)`
  a {
    div {
      background-color: ${p => p.theme.colors.primary500};
    }
  }
`;

const TagBarWrapperCss = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
`;
