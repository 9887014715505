import React from 'react';
import { FAQ as FAQDto } from 'src/services/articles';
import { FAQ } from '@shapegames/web-library-quick-draw-ui/dist/FAQ';
import draftToHtml from 'draftjs-to-html';
import { mapArticleContentToDraftJSFormat } from 'src/containers/Article/Detail/helpers';

interface FormattedFAQProps {
  inputFAQ: FAQDto;
}

const FormattedFAQ: React.FC<FormattedFAQProps> = ({ inputFAQ }) => {
  const formattedFAQs = inputFAQ?.map(faqObj => {
    return {
      question: faqObj.question,
      answer: draftToHtml(mapArticleContentToDraftJSFormat(faqObj.answer)),
    };
  });
  return (
    <>
      {formattedFAQs && formattedFAQs.length > 0 && (
        <FAQ content={formattedFAQs} title="Frequently Asked Questions" />
      )}
    </>
  );
};

export default FormattedFAQ;
