import React from 'react';
import { SWRConfig } from 'swr';
import { withLayout } from 'src/components/Layout';
import withGlobalData from 'src/hocs/with-global-data';
import {
  getStaticPaths as _getStaticPaths,
  getStaticProps as _getStaticProps,
  StaticGroupSlugProps,
} from 'src/data-fetching/fetch-groupsSlug';
import RouterFallback from 'src/components/RouterFallback';
import ArticleGroupPageContainer from 'src/containers/ArticleGroupPageContainer';

function ArticlesGroupPage(props: StaticGroupSlugProps) {
  return (
    <RouterFallback error={'error' in props}>
      <SWRConfig value={{ fallback: props.fallback }}>
        <ArticleGroupPageContainer slug={props.slug} />
      </SWRConfig>
    </RouterFallback>
  );
}

export const getStaticPaths = _getStaticPaths;
export const getStaticProps = withGlobalData(_getStaticProps, {
  numOfAdverts: 1,
});

export default withLayout(ArticlesGroupPage as any);
