import React from 'react';
import Head from 'next/head';
import draftToHtml from 'draftjs-to-html';
import { mapArticleContentToDraftJSFormat } from '../containers/Article/Detail/helpers';
import { FAQ } from 'src/services/articles';

interface NextSeoFAQPageJsonLdProps {
  faq?: FAQ;
}

// Inspired from NextSeo implementation, however they escape HTML in FAQ content despite it being supported
export default function FAQPageJsonLd({ faq }: NextSeoFAQPageJsonLdProps) {
  if (!faq) return null;

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: formatMainEntity(faq),
          }),
        }}
      />
    </Head>
  );
}

function formatMainEntity(faq: FAQ) {
  return faq?.map(faq => {
    return {
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: draftToHtml(mapArticleContentToDraftJSFormat(faq.answer)),
      },
    };
  });
}
