import React from 'react';
import { RichText } from 'src/services/articles';
import draftToHtml from 'draftjs-to-html';
import { mapArticleContentToDraftJSFormat } from 'src/containers/Article/Detail/helpers';
import InnerHTML from 'dangerously-set-html-content';
import styled from 'styled-components';

interface FormattedRichTextProps {
  text: RichText | null;
}

const FormattedRichText: React.FC<FormattedRichTextProps> = ({ text }) => {
  const html = draftToHtml(mapArticleContentToDraftJSFormat(text));

  // TODO: Fix issue on text to return font-familiy
  const tempHTMLFix = html.replace(/(font-family):([^;]*);/g, '');

  return (
    <HtmlStylesCss>
      {text && typeof window !== 'undefined' ? (
        <InnerHTML html={tempHTMLFix} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: tempHTMLFix }} />
      )}
    </HtmlStylesCss>
  );
};

export default FormattedRichText;

const HtmlStylesCss = styled.div`
  p {
    ${p => p.theme.getFontStyle('primary', 'medium')}
    font-size: ${p => p.theme.typography.textBase};
    line-height: 1.63;
    color: ${p => p.theme.colors.black};
    opacity: 0.8;
  }

  strong {
    ${p => p.theme.getFontStyle('primary', 'bold')}
  }

  h2 {
    font-size: 20px;
    margin-top: 25px;
  }

  img,
  iframe {
    max-width: 100%;
  }

  a {
    color: ${p => p.theme.colors.primary500};
    text-decoration: underline;
  }
`;
