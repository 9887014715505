import React from 'react';
import styled from 'styled-components';

interface ContentLayoutProps {
  main: React.ReactNode;
  sidebar?: React.ReactNode;
}

const ContentLayout: React.FC<ContentLayoutProps> = ({ main, sidebar }) => {
  return (
    <ContentLayoutCss>
      <MainContentWrapperCss>{main}</MainContentWrapperCss>
      <SidebarWrapperCss>{sidebar}</SidebarWrapperCss>
    </ContentLayoutCss>
  );
};

export default ContentLayout;

const MainContentWrapperCss = styled.div`
  flex: 1;

  margin-bottom: ${p => p.theme.sizes.spacing6};
  ${p => p.theme.breakpoints.lg} {
    margin-bottom: 0px;

    margin-right: ${p => p.theme.sizes.spacing10};
  }

  ${p => p.theme.breakpoints.maxLg} {
    flex-direction: column;
  }
`;

const ContentLayoutCss = styled.div`
  display: flex;

  ${p => p.theme.breakpoints.maxLg} {
    flex-direction: column;
  }
`;

const SidebarWrapperCss = styled.div`
  position: relative;
`;
