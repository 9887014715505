import { Article, RichTextBlock } from 'src/services/articles/types/article';
import { RawDraftContentState, RawDraftInlineStyleRange } from 'draft-js';
import { isObject } from '@shapegames/web-library-utils';

export function kebabize(str: string) {
  return str
    .split('')
    .map((letter: string, idx: number) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    })
    .join('');
}

export function camelToKebabCase(obj: any) {
  if (!isObject(obj)) {
    return obj;
  }

  return Object.keys(obj).reduce(
    (res, key) => ({
      ...res,
      [kebabize(key)]: obj[key],
    }),
    {},
  );
}

export function mapArticleContentToDraftJSFormat(
  body: Article['body'],
): RawDraftContentState {
  if (!body?.blocks) {
    return {
      entityMap: {},
      blocks: [],
    };
  }

  return {
    entityMap: body.entityMap,
    blocks: body.blocks.map((block: RichTextBlock) => ({
      text: block.value,
      inlineStyleRanges: (block.inlineStyleRanges ||
        []) as RawDraftInlineStyleRange[],
      entityRanges: block.entityRanges || [],
      key: block.key,
      depth: block.depth,
      data: camelToKebabCase(block.data),
      type: block.type,
    })),
  };
}
