import React from 'react';
import { ArticlesPaged } from 'src/services/articles';
import styled from 'styled-components';
import InfinityLoading from 'src/components/InfinityLoading';
import ArticlesGroupItem from '../../ArticlesGroup/Item/ArticlesGroupItem';

interface TwoRowListGridProps {
  data: ArticlesPaged[];
  onFetchMore: () => void;
  loading: boolean;
}

const TwoRowListGrid: React.FC<TwoRowListGridProps> = ({
  loading,
  data,
  onFetchMore,
}) => {
  const items = data.map(page => page.items).flat();

  const groups = items.map((group, idx) => (
    <ArticlesGroupItem
      key={`article-${idx}`}
      variant={'vertical'}
      article={group}
    />
  ));

  const hasMore = data?.[data.length - 1].paging.hasMore;
  return (
    <div>
      <InfinityLoading
        isLoading={loading}
        onFetchMore={onFetchMore}
        shouldFetchMore={hasMore}
        withButton
      >
        <GridCss>{groups}</GridCss>
      </InfinityLoading>
    </div>
  );
};

export default TwoRowListGrid;

const GridCss = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${p => p.theme.sizes.spacing4};
  ${p => p.theme.breakpoints.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
