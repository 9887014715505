import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@shapegames/web-library-quick-draw-ui/dist/Button/Button';
import { Advertisement } from 'src/services/articles';
import PromotionBanner from '../Promotion';
import { imageUrlMaker } from 'src/utils/image-helpers';

interface SidebarProps {
  adverts: Advertisement[] | null;
  children?: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ adverts, children }) => {
  const ads = useMemo(() => {
    return adverts?.map(
      advertisement =>
        advertisement &&
        advertisement.image && (
          <PromotionBanner
            key={`ad-${advertisement.id}`}
            href={advertisement.url}
            imageUrl={imageUrlMaker({
              imageUrl: advertisement.image.url,
              cropType: 'squareCrop',
              crop: advertisement.squareCrop,
            })}
          />
        ),
    );
  }, [adverts]);

  const [firstAd, secondAd, ...otherAds] = ads || [];

  return (
    <SidebarCss>
      {firstAd}
      {secondAd}
      {children}
      {otherAds}
      <SignUpButtonCss
        onClick={() => window.open('https://betjack.com/en/login', 'blank')}
      >
        Sign up
      </SignUpButtonCss>
    </SidebarCss>
  );
};

export default Sidebar;

const SignUpButtonCss = styled(Button)`
  background-color: ${p => p.theme.colors.neutral900};
  margin-bottom: ${p => p.theme.sizes.spacing6};
`;

const SidebarCss = styled.div`
  top: ${p => p.theme.sizes.spacing6};
  position: sticky;
  display: flex;
  flex-direction: column;

  align-items: center;

  & > div,
  ul:not(:last-child) {
    margin-bottom: ${p => p.theme.sizes.spacing6} !important;
  }

  ${p => p.theme.breakpoints.lg} {
    width: 317px;
  }
`;
