import styled from 'styled-components';
import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

interface PromotionBannerProps {
  imageUrl: string;
  href: string;
}

const height = 317;
const width = 317;

function PromotionBanner(props: PromotionBannerProps) {
  return (
    <PromotionBannerCss>
      <Link href={props.href} target="_blank">
        <Image
          src={props.imageUrl}
          width={width}
          height={height}
          alt="description"
        />
      </Link>
    </PromotionBannerCss>
  );
}

export default PromotionBanner;

const PromotionBannerCss = styled.div`
  display: flex;
  min-height: ${height}px;
  width: ${width}px;
`;
